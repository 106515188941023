@font-face {
  font-family: MonumentExtended;
  src: url(https://static.mygolfdna.com/fonts/PPMonumentExtended-Regular.woff2);
}

@font-face {
  font-family: MonumentExtendedBlack;
  src: url(https://static.mygolfdna.com/fonts/PPMonumentExtended-Black.woff2);
}

#pricing-carousel figure p {
  font-size: 1rem;
}

#pricing-carousel figure {
  padding-right: 10px;
  padding-left: 10px;
}

main > .container {
  padding: 60px 15px 0;
}

.accordion-header > button > div > p {
  margin: 0 !important;
}
.home-heading {
  font-family: MonumentExtendedBlack, sans-serif;
  font-size: 1.6rem;
  letter-spacing: 1px;
}

.progresses{
  display: flex;
  align-items: center;
}

.line{

  width: 40px;
  height: 3px;
  background: #d5d5d5;
  margin: 3px;
}

.img-gradient{
  position:relative;
  display:inline-block;
}

.img-gradient-progress-tracker{
  /**position:relative;**/
  display:inline-block;
}

.img-gradient:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(rgba(27, 38, 49, 0.7), rgba(27, 38, 49, 0.7));
  border-radius: 4px;
}
.img-gradient img{
  display:block;
}

#instructors {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  #instructors {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (max-width: 480px) {
  #instructors {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.img-instructor{
  position:relative;
  display:inline-block;
}

.img-instructor:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  border-radius: 4px;
}
.img-instructor img{
  display:block;
}
.img-gradient-progress-tracker:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(rgba(27, 38, 49, 0.4), rgba(27, 38, 49, 0.2));
  border-radius: 4px;
}
.img-gradient-progress-tracker img{
  display:block;
}

.steps{

  display: flex;
  background-color: #d5d5d5;
  color: #fff;
  font-size: 12px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 3px;

}

/* Carousel base class */
.carousel {
  /* margin-bottom: 4rem;*/
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 30rem;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .home-heading {
    font-family: MonumentExtendedBlack, sans-serif;
    font-size: 2.5rem;
    letter-spacing: 1px;
  }
  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 25rem;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .line{

  width: 120px;
  height: 3px;
  background: #d5d5d5;
  margin: 3px;
}


  .steps{

    display: flex;
    background-color: #d5d5d5;
    color: #fff;
    font-size: 12px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 3px;

  }
}


.video-player {
  display: block;
  margin: auto;
}

.navbar-brand {
  font-family: MonumentExtended, sans-serif;
}

.monument-font {
  font-family: MonumentExtended, sans-serif !important;
}

.navbar {
  z-index: 1;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  flex-grow: 1;
}

.box {
  height: 100%;
}

.pricing {
  position: relative;
}

.pricing-content {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.VideoInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

.VideoInput_input {
  display: none;
}

.VideoInput_video {
  display: block;
  margin: 0;
}

.VideoInput_footer {
  background: #eee;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
}


.home-bg {
  background: linear-gradient(rgba(27, 38, 49, 0.7), rgba(27, 38, 49, 0.7)), url(https://static.mygolfdna.com/your_teacher.jpg);
  background-size: cover !important;
  background-position: top !important;
  position: fixed;
  min-width: 100%;
  max-height: 800px;
  width: auto;
  height: auto;
}
@media (max-width: 480px) {
  .home-bg {
    background: linear-gradient(rgba(27, 38, 49, 0.7), rgba(27, 38, 49, 0.7)), url(https://static.mygolfdna.com/homepage2.png);
  }
}

@media (min-width: 1300px) {
  .home-bg {
    background: linear-gradient(rgba(27, 38, 49, 0.7), rgba(27, 38, 49, 0.7)), url(https://static.mygolfdna.com/homepage_desk.png);
  }
}

.main-nav .nav-link {
  color: #ffffff !important;
}

.main-nav .nav-link:hover {
  color: #a6b1bf !important;
}

.teacher-bg {
  background: linear-gradient(rgba(27, 38, 49, 0.7), rgba(27, 38, 49, 0.7)), url(https://static.mygolfdna.com/homepage.jpg);
  background-size: cover !important;
  background-position: top !important;
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
.swiper-container {
  width: 480px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}


/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100vh;
  overflow: auto;
}

/* Sidebar links */
.sidebar a {
  display: block;
  color: black !important;
  padding: 16px;
  text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
  background-color: #1B2631;
  color: white !important;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  background-color: #1b26317d;
  color: white !important;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 100vh;
}

.test-div-inner {
  padding-bottom:56.1%;
  background:#EEE;
  height:0;
  position:relative;
}

.test-div-inner-progress-tracker {
  padding-bottom:37.1%;
  height:0;
  position:relative;
}

.chart-progress-tracker {
  height: 250px;
}

.test-image {
  width:100%;
  height:100%;
  display:block;
  position:absolute;
}

.image-uploader {
  border-radius: 50%;
 /* border: 10px solid rgba(255,91,37);*/
}

.lesson-card {
  height: 320px !important;
}

.lesson-progress-tracker {
  height: 320px !important;
}

.comment-text {
  font-size: 14px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}

}

.video-container {
  height: 400px;
}

@media screen and (max-width: 420px) {
  .video-container {
    max-height: 200px;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 420px) {
  .sidebar a {
    text-align: center;
    float: none;
  }

  .image-uploader {
    width: 100px !important;
    height: 100px !important;
    /*border: 8px solid rgba(255,91,37);*/
  }

  .lesson-card {
    height: auto !important;
  }

  .chart-progress-tracker {
    height: 150px;
  }

  .lesson-progress-tracker {
    height: 240px !important;
  }
}


/** Step Wizard */
.step-wrapper {
  position: relative;
}

.step {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.active {
  opacity: 1;
  pointer-events: inherit;
  position: relative;
  z-index: 1;
}

.font-weight-bold {
  font-weight: bold !important;
}

.monument-font-black {
  font-family: MonumentExtendedBlack, sans-serif;
}

.grey-bg {
  background-color: #97a0aa;

}

.dark-bg {
  background-color: #1B2631;
}

.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /*
  background-color: rgb(27 38 122 / 85%);
  */
}
.masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.masthead .masthead-content h1, .masthead .masthead-content .h1 {
  font-size: 2.5rem;
}
.masthead .masthead-content p {
  font-size: 1.2rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 75vw;
    min-height: 0;
    padding-bottom: 0;
  }
  .masthead:before {
    transform: skewX(-9deg);
    transform-origin: top right;
  }
  .masthead .masthead-content {
    padding: 0 9rem 0 2rem;
  }
  .masthead .masthead-content h1, .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }
  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}
@media (min-width: 1200px) {
  .masthead {
    width: 65vw;
  }
}

.bg-dark-blue {
  background-color: #1B2631;
}

.bg-orange {
  background-color: #ff5b25;
}
.text-orange {
  color: #ff5b25;
}
:root {
  --swiper-navigation-size: 16px !important;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 44);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 15px;
  color: #ff5b25 !important;
  background-color: #a6b1bf;
}

.nav-link.active {
  color: #6c757d !important;
}

.offcanvas-backdrop-blue {
  background-color: #1b263130;
}

// Override default variables before the import
$body-bg: #fff;
$secondary: #ff5b25;
$primary: #1B2631;
$nav-pills-link-active-bg: #1B2631;
$nav-link-color: #1B2631;
$nav-link-hover-color: #1B2631;
$nav-link-active-color: #6c757d;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");
