.home-container {
    height: 100%;
    position: relative;
}

.home-container:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #1B2631;
    opacity: 0.7;
}
