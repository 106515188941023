/* Add a black background color to the top navigation */
.topnav {
    background-color: #1b2631;
    overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
    display: inline-block;
    color: #fff;
    text-align: center;
    padding: 10px 12px;
    text-decoration: none;
    /***font-size: 17px;***/
    border-bottom: 3px solid transparent;
}

.topnav a:hover {
    border-bottom: 3px solid #ff5b25;
}

.topnav a.active {
    border-bottom: 3px solid #ff5b25;
}
